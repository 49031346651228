import Button from "@mui/material/Button";
import React from "react";
import useGlobeData from "./useGlobeData.js";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
export default function RotateGlobeToggleButton() {
  const { setState, rotate } = useGlobeData();

  const icon = rotate ? <PauseCircleOutlineIcon /> : <ThreeSixtyIcon />;

  const onClick = () => {
    setState({ rotate: !rotate });
  };

  return (
    <Button size="small" startIcon={icon} color="secondary" variant="outlined" onClick={onClick}>
      {rotate ? "Pause" : "Rotate"}
    </Button>
  );
}
