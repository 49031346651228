// Helper function to generate a random digit (0-9)
function getRandomDigit() {
  return Math.floor(Math.random() * 10).toString();
}

// Helper function to generate a random letter string of length 3
function getRandomString() {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return characters.charAt(Math.floor(Math.random() * characters.length));
}

function replaceCharacters(input) {
  if (!input) return input;
  // Replace each digit with a random digit and each letter with a random string
  return input.replace(/./g, function (character) {
    if (/[0-9]/.test(character)) {
      return getRandomDigit();
    } else if (/[a-zA-Z]/.test(character)) {
      return getRandomString();
    } else {
      return character; // Keep non-alphanumeric characters as is
    }
  });
}

export default function anonymiseData(data) {
  data.bookings = data.bookings.map((item) => ({
    ...item,
    bookingReference: replaceCharacters(item.bookingReference),
    segments: item.segments?.map((segment) => ({
      ...segment,
      eTicket: replaceCharacters(segment.eTicket),
      booking: {
        ...segment.booking,
        bookingReference: replaceCharacters(segment.booking.bookingReference),
      },
    })),
    stays: item.stays?.map((segment) => ({
      ...segment,
      booking: {
        ...segment.booking,
        bookingReference: replaceCharacters(segment.booking.bookingReference),
      },
    })),
  }));

  data.loyaltyPrograms = data.loyaltyPrograms.map((item) => ({
    ...item,
    programId: replaceCharacters(item.programId),
  }));

  data.stays = data.stays.map((item) => ({
    ...item,
    booking: {
      ...item.booking,
      bookingReference: replaceCharacters(item.booking.bookingReference),
    },
  }));

  data.flights = data.flights.map((item) => ({
    ...item,
    eTicket: replaceCharacters(item.eTicket),
    booking: {
      ...item.booking,
      bookingReference: replaceCharacters(item.booking.bookingReference),
    },
  }));

  data.segments = data.segments.map((item) => ({
    ...item,
    eTicket: replaceCharacters(item.eTicket),
    booking: {
      ...item.booking,
      bookingReference: replaceCharacters(item.booking.bookingReference),
    },
  }));

  return data;
}
