import { formatCurrencyName } from "../../lib/formatCurrency.js";

function generateCurrencyItem(id) {
  return { id, title: formatCurrencyName(id) };
}

const currency = [
  generateCurrencyItem("USD"),
  generateCurrencyItem("BRL"),
  generateCurrencyItem("EUR"),
  generateCurrencyItem("GBP"),
  generateCurrencyItem("CHF"),
  generateCurrencyItem("ILS"),
  generateCurrencyItem("NOK"),
  generateCurrencyItem("DKK"),
  generateCurrencyItem("SGD"),
  generateCurrencyItem("MYR"),
  generateCurrencyItem("CLP"),
].sort((a, b) => a.title.localeCompare(b.title, "en", { sensitivity: "base" }));

export default currency;
