import formatNumber from "./formatNumber.js";

/**
 * Format a value as a currency string.
 * @param {number} value - A value to render such as "1234.56"
 * @param {String} currency - A currency code, such as EUR, USD, GBP
 * @param {String[]|String} [locales] - The locale to use for formatting the string (i.e. en-GB)
 * @returns {String} - A formatted number like "£1,234.56"
 */
export default function formatCurrency(value, currency, locales) {
  try {
    return new Intl.NumberFormat(locales || "en-GB", { style: "currency", currency }).format(value);
  } catch (ex) {
    // ignore
  }

  return formatNumber(value);
}

export function formatCurrencyName(currency, locale) {
  try {
    return new Intl.DisplayNames([locale || "en"], { type: "currency" }).of(currency);
  } catch (ex) {
    return `Unrecognised ${currency}`;
  }
}
