import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import { db } from "../../models/db.js";
import FixedFooterAppBar from "../FixedFooterAppBar.js";
import MainScrollLayout from "../MainScrollLayout.js";
import GlobalMapLoader from "./GlobalMapLoader.js";
import GlobeDataProvider from "./GlobeDataProvider.js";
import GlobeStatsNumbers from "./GlobeStatsNumbers.js";
import RotateGlobeToggleButton from "./RotateGlobeToggleButton.js";

export default function GlobePage() {
  const flights = useLiveQuery(() => db.flights.toArray());

  return (
    <GlobeDataProvider flights={flights}>
      <MainScrollLayout fixed footerBar>
        <GlobalMapLoader />
      </MainScrollLayout>
      <FixedFooterAppBar>
        <RotateGlobeToggleButton />
        <GlobeStatsNumbers />
      </FixedFooterAppBar>
    </GlobeDataProvider>
  );
}
