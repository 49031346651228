import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React from "react";
import { useIntl } from "react-intl";
import useGlobeData from "./useGlobeData.js";
export default function GlobeStatsNumbers() {
  const { stats } = useGlobeData();
  const { formatNumber } = useIntl();

  const { available, lifetimeFlights, lifetimeAirports, lifetimeUniqueRoutes, lifetimeCountries } = stats;

  if (!available) {
    return null;
  }

  return (
    <>
      <Box sx={{ flex: "1 1 auto" }} />
      <Typography variant="button" component="div" color="textSecondary">
        {`Flights: ${formatNumber(lifetimeFlights || 0)}`}
      </Typography>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Typography variant="button" component="div" color="textSecondary">
        {`Airports: ${formatNumber(lifetimeAirports || 0)}`}
      </Typography>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Typography variant="button" component="div" color="textSecondary">
        {`Routes: ${formatNumber(lifetimeUniqueRoutes || 0)}`}
      </Typography>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Typography variant="button" component="div" color="textSecondary">
        {`Countries: ${formatNumber(lifetimeCountries || 0)}`}
      </Typography>
    </>
  );
}
