import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import useCurrency from "../../contexts/Currency/useCurrency.js";

export default function CurrencySelectField({ value, onChange, disabled }) {
  const { currency } = useCurrency();

  const isEmpty = currency.length === 0;
  return (
    <TextField
      select
      label="Currency"
      variant="outlined"
      margin="none"
      size="small"
      autoComplete="off"
      fullWidth
      value={value || ""}
      onChange={onChange}
      error={isEmpty}
      disabled={disabled || isEmpty}
      slotProps={{
        select: {
          MenuProps: {
            MenuListProps: {
              dense: true,
            },
          },
          renderValue: (selected) => {
            // selected = "GBP", see `value={currencyType.id}` below
            const selectedCurrency = currency.find((item) => item.id === selected);

            return (
              <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <Typography sx={{ flex: "1 1 auto" }} variant="body1">
                  {selectedCurrency.title}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} display="inline">
                  {selectedCurrency.id}
                </Typography>
              </Box>
            );
          },
        },
      }}
    >
      {currency.map((currencyType) => (
        <MenuItem key={currencyType.id} value={currencyType.id} selected={currencyType.id === value}>
          <ListItemText>{currencyType.title}</ListItemText>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {currencyType.id}
          </Typography>
        </MenuItem>
      ))}
    </TextField>
  );
}
