import { BOOKED } from "../../models/BookingConstants.js";

export default function calculateStats(data) {
  if (!data) {
    return {
      available: false,
    };
  }

  const { flights, uniqueAirports, uniqueRoutes } = data;

  let uniqueCountries = uniqueAirports.reduce((acc, airport) => {
    if (airport.country && !acc.has(airport.country.code)) {
      acc.set(airport.country.code, airport.country);
    }

    return acc;
  }, new Map());

  uniqueCountries = [...uniqueCountries].map(([, item]) => item);

  return {
    available: true,
    lifetimeFlights: flights.filter(
      (current) => !(current.booking.status !== BOOKED || current.isSkipped || current.isSecondaryPassenger),
    ).length,
    lifetimeAirports: uniqueAirports.length,
    lifetimeUniqueRoutes: uniqueRoutes.length,
    lifetimeCountries: uniqueCountries.length,
  };
}
