import calculateStats from "./calculateStats.js";

export default function TripDetailsMangerInitialState({ flights, stations, animate, sequence }) {
  return {
    flights: flights || undefined,
    stations: stations || undefined,
    isLoaded: false,
    uniqueRoutes: [],
    labels: [],
    stats: calculateStats(undefined),
    animate: animate === true, // Should the arc lines be animated
    sequence: sequence === true, // Should the flights be unique or in sequence.
    rotate: sequence !== true, // Should the globe spin
  };
}
