import { createContext } from "react";

const GlobeDataContext = createContext({
  flights: undefined,
  stations: undefined,
  isLoaded: false,
  uniqueRoutes: [],
  uniqueAirports: [],
  labels: [],
  rotate: false,
  sequence: false,
  animate: false,
  setState: () => {},
  stats: undefined,
});

export default GlobeDataContext;
